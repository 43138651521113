/* eslint-disable max-len */
import { Checkbox, Input, TextArea } from '@basic';
import { Button } from '@chakra-ui/react';
import { sitedata } from '@data';
import { RootState } from '@redux/store';
import {
  SetDialogState,
  setDialogState as setDialogStateAction,
} from '@redux/ui';
import {
  SetRecaptcha,
  setRecaptcha as setRecaptchaAction,
} from '@redux/vendorScripts';
import { titleCase } from '@utils';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

declare global {
  interface Window {
    grecaptcha: any;
    submitValidatedForm: any;
  }
}

interface Props {
  isRecaptchaLoaded: boolean;
  setDialogState: SetDialogState;
  setRecaptcha: SetRecaptcha;
  theme: string;
}

const checkboxLabel = (
  <>
    Agree to our{' '}
    <a
      aria-label="privacy policy"
      className="font-semibold text-indigo-700 hover:underline dark:text-white"
      href={`${process.env.GATSBY_MARKETING_SITE_URL}/privacy-policy`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <span className="whitespace-nowrap">privacy policy</span>.
    </a>
    <br />
    Your personal data is only used to respond to your form submission.
  </>
);

const checkboxRequiredText = (
  <span className="text-red-700 dark:text-red-400">
    You have to agree to our{' '}
    <a
      aria-label="privacy policy"
      className="font-semibold text-black hover:underline dark:text-white"
      href={`${sitedata.siteUrl}/privacy-policy`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <span className="whitespace-nowrap">privacy policy</span>
    </a>
    .
    <br />
    Your personal data is only used to respond to your form submission.
  </span>
);

const Component = (props: Props) => {
  //
  const { isRecaptchaLoaded, setDialogState, setRecaptcha, theme } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!isRecaptchaLoaded) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      document.body.appendChild(script);
      setRecaptcha(true);
    }
  }, []);

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm();

  const submitForm = async (token: string) => {
    const { email, message, name, phone } = getValues();
    const formattedName = titleCase(name);
    const formattedEmail = email.toLowerCase();

    const payload = {
      detail: {
        metadata: {
          Topic: ['contactForm/submitted'],
        },
        payload: {
          email: formattedEmail,
          message,
          name: formattedName,
          phone,
          token,
        },
      },
      detailType: 'marketingSite',
      time: Date.now(),
    };

    try {
      window.grecaptcha.reset();

      //TODO: Disable button
      //TODO: Trigger wait box
      setIsSubmitting(true);

      const res = await fetch(
        `${process.env.GATSBY_API_ENDPOINT}/v1/admin/event`,
        {
          body: JSON.stringify(payload),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          method: 'POST',
        },
      );
      if (res.status === 200) {
        // const data = await res.json();
        // console.log(data);
        reset({
          agree: false,
          email: '',
          message: '',
          name: '',
          phone: '',
        });

        setIsSubmitting(false);
        setDialogState({
          isShow: true,
          message: 'Thank you',
          title: 'Contact form submission',
        });
      }
    } catch (error) {
      console.log(error);
      //TODO: trigger error box
    }
  };

  const isBrowser = typeof window !== 'undefined';

  if (isBrowser) {
    window.submitValidatedForm = (token: string) => {
      if (token) {
        submitForm(token);
      }
    };
  }

  return (
    <form onSubmit={handleSubmit(() => window.grecaptcha.execute())}>
      <div className="relative px-2 sm:px-0">
        <Input
          classes="mb-12"
          error={errors.name && errors.name.message}
          label="Name"
          name="name"
          register={register}
          requiredText="Your full name is required"
          size="lg"
        />
      </div>

      <div className="relative px-2 sm:px-0">
        <Input
          classes="mb-12"
          error={errors.phone && errors.phone.message}
          label="Phone (Country code and mumbers only)"
          name="phone"
          patternText="Please provide a valid phone number"
          patternValue={/^-?[0-9]\d*\.?\d*$/i}
          placeholder="6012341234"
          register={register}
          requiredText="Your phone number is required"
          size="lg"
        />
      </div>

      <div className="relative px-2 sm:px-0">
        <Input
          classes="mb-12"
          error={errors.email && errors.email.message}
          label="Email"
          name="email"
          patternText="Please provide a valid email address"
          patternValue={/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i}
          register={register}
          requiredText="Your email is required"
          size="lg"
        />
      </div>

      <div className="relative px-2 sm:px-0">
        <TextArea
          classes="mb-20"
          error={errors.message && errors.message.message}
          label="Write your message here"
          name="message"
          register={register}
          requiredText="Your message is required"
        />
      </div>

      <div className="relative px-2 mb-6 sm:px-0">
        <Checkbox
          control={control}
          error={Boolean(errors.agree)}
          label={checkboxLabel}
          name="agree"
          requiredText={checkboxRequiredText}
          value={watch('agree')}
        />
      </div>

      <div
        className="sr-only g-recaptcha"
        data-badge="inline"
        data-callback="submitValidatedForm"
        data-formfield="captcha"
        data-sitekey={process.env.GATSBY_ReCAPTCHA_SITEKEY}
        data-size="invisible"
      />

      <div className="px-2 sm:px-0">
        <Button
          isFullWidth
          isLoading={isSubmitting}
          loadingText="Submitting"
          size="lg"
          type="submit"
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

const states = (state: RootState) => {
  return {
    isRecaptchaLoaded: state.scripts.isRecaptchaLoaded,
    theme: state.ui.theme,
  };
};

const dispatch = {
  setDialogState: setDialogStateAction,
  setRecaptcha: setRecaptchaAction,
};

export const Form = connect(states, dispatch)(Component);
