import { Button } from '@chakra-ui/react';
import { sitedata } from '@data';
import {
  EmailIcon,
  FacebookIcon,
  LinkedInIcon,
  PinIcon,
  TwitterIcon,
  WhatsappIcon,
} from '@svg';
import React from 'react';
import xw from 'twin.macro';
import { Form } from './form';

const styles = {
  link: xw`
    ml-4 font-semibold tracking-wide text-base text-black dark:text-white hover:underline
  `,
};

const Component = () => {
  //
  const { locations, socialMedia } = sitedata;
  const {
    location1: { address, emails, googleMap, phones },
  } = locations;
  const { facebookProfileId, linkedInHandle, twitterHandle } = socialMedia;

  return (
    <div className="max-w-2xl pt-16 pb-20 mx-auto lg:max-w-5xl xs:px-6 lg:pt-24 lg:pb-28 md:px-8">
      <div className="grid grid-cols-1 gap-20 lg:gap-6 lg:grid-cols-2">
        <div className="px-2 py-6 mr-2 md:py-12 md:px-8 sm:rounded-lg background--shaded xs:px-6">
          <h1 className="text-4xl font-extrabold tracking-tight text-center sm:text-5xl text--color-heading-1 lg:text-left">
            Get in touch
          </h1>
          <p className="mt-2 text-lg font-medium text-center text-normal sm:text-2xl text--color-body-1 lg:text-left">
            Fill in the form to start a conversation
          </p>

          <address className="max-w-sm mx-auto mb-10 not-italic lg:mx-0">
            <div className="flex items-start mt-8 mb-8 text--color-body-2">
              <PinIcon className="hidden w-8 h-8 text-gray-500 xs:block" />
              <a
                aria-label="opens google map"
                css={[styles.link]}
                href={googleMap}
                rel="noopener noreferrer"
                target="_blank"
              >
                {address.streetAddress1},
                <br />
                {address.streetAddress2},
                <br />
                {address.postalCode} {address.addressLocality},
                <br />
                {address.addressRegion},
                <br />
                {address.country}
              </a>
            </div>

            <div className="flex items-start mb-8 text--color-body-2">
              <WhatsappIcon className="hidden w-8 h-8 text-gray-500 xs:block" />
              <div>
                {phones.map((phone) => {
                  const whatsapp = `+${phone.countryCode}${phone.telephone}`;

                  return (
                    <div key={phone.telephone} className="grid mb-2">
                      {/* <a
                        css={[styles.link]}
                        href={`tel:+${phone.countryCode}${phone.telephone}`}
                      >
                        +{phone.countryCode} {phone.telephone}
                      </a> */}
                      <span className="ml-4">Click to Whatsapp</span>
                      <a
                        css={[styles.link]}
                        href={`https://wa.me/${whatsapp.replace(/\s+|-/g, '')}`}
                      >
                        +{phone.countryCode} {phone.telephone}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="flex items-start mt-2 text--color-body-2 ">
              <EmailIcon className="hidden w-8 h-8 text-gray-500 xs:block" />
              <div>
                {emails.map((email) => (
                  <div key={email} className="mb-2">
                    <a css={[styles.link]} href={`mailto:${email}`}>
                      <span className="whitespace-nowrap">{email}</span>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </address>

          <div className="py-8 text-center bg-gray-200 rounded-lg dark:bg-gray-700">
            <div className="mb-4 font-semibold text--color-heading-1">
              Follow / See Profile
            </div>
            <div className="flex justify-center mb-6 space-x-6">
              <a
                aria-label="linkedIn"
                href={`https://www.linkedin.com/in/${linkedInHandle}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <LinkedInIcon />
              </a>
              <a
                aria-label="facebook"
                href={`https://www.facebook.com/profile.php?id=${facebookProfileId}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FacebookIcon />
              </a>
              <a
                aria-label="twitter"
                href={`https://twitter.com/${twitterHandle}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <TwitterIcon />
              </a>
            </div>
            <div className="grid gap-2 xs:grid-cols-2">
              <Button as="a" href="/cv.pdf" size="md" target="_blank">
                Download CV (ppt)
              </Button>
              <Button as="a" href="/cv2.pdf" size="md" target="_blank">
                Download CV (text)
              </Button>
            </div>
          </div>
        </div>
        <div className="lg:pt-10">
          <Form />
        </div>
      </div>
    </div>
  );
};

export const Main = Component;
